import Swiper from "swiper/js/swiper";

function swiper($selector, $options) {
  return new Swiper($selector, $options);
}


swiper('.hero-slider', {
  autoplay: true,
  loop: true,
  speed: 1000,
  // navigation: {
  //   nextEl: '.swiper-button-next',
  //   prevEl: '.swiper-button-prev',
  // },
});
