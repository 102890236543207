import $ from "jquery";

var  getDateString = function(date, format) {
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  getPaddedComp = function(comp) {
      return ((parseInt(comp) < 10) ? ('0' + comp) : comp)
  },
  formattedDate = format,
  o = {
      "y+": date.getFullYear(), // year
      "M+": months[date.getMonth()], //month
      "d+": getPaddedComp(date.getDate()), //day
      "h+": getPaddedComp((date.getHours() > 12) ? date.getHours() % 12 : date.getHours()), //hour
      "m+": getPaddedComp(date.getMinutes()), //minute
      "s+": getPaddedComp(date.getSeconds()), //second
      "S+": getPaddedComp(date.getMilliseconds()), //millisecond,
      "t+": (date.getHours() >= 12) ? 'pm' : 'am'
  };

  for (var k in o) {
      if (new RegExp("(" + k + ")").test(format)) {
          formattedDate = formattedDate.replace(RegExp.$1, o[k]);
      }
  }
  return formattedDate;
};

  /* ============================= */
  /* ============================= */
  /* ============================= */
  /* ============================= */
  /* ============================= */

  // LOAD MORE BLOG

  let page = 2;

  $("#loadMoreNewsPost").click(function() {
    const button = $(this);

    $.ajax({
      url: `/wp-json/wp/v2/${button.data('post-type')}?_embed&per_page=10&page=${page}`,
      type: "GET",


      beforeSend: function() {
        button.addClass("spinning");
      },

      success: function(data) {
        if (data) {
          $("#grid").append(postsHTML(data));
          if (button.data("max-pages") == page) {
            button.remove();
          } else {
            page++;
          }
        } else {
          button.remove();
        }
        button.removeClass("spinning");
        magicGrid.listen();
        $(".blogPost__img").on("load", function() {
          magicGrid.listen();
        });
      }
    });
  });

  function postsHTML(posts) {
    let postsHTML = "";

    posts.forEach(function(post) {
      console.log(post)
      const title = post.title.rendered,
        link = post.link,
        excerpt = post.excerpt.rendered,
        image = post._embedded["wp:featuredmedia"][0].source_url;

      // console.log(post);
      // content = strip(post.content.rendered).substring(0, 229, "...");

      postsHTML += `<div class="item blogPost__item">
      <div class="blogPost__content">
      <div class="blogPost__imgWrap">
      <img class="img-fluid blogPost__img" src="${image}" alt="">
      </div>
      <div class="blogPost__text">
      <h3 class="date-text smallest-header font-weight-bold mb-0">
      ${getDateString(new Date(post.date), "M d, y h:mt")}
      </h3>
      <div class="blogPost__title">
      <h2>${title}</h2>
      </div>
      <div class="blogPost__desc">
      ${excerpt}
      </div>
      <a href="${link}" class="btn btn__clip">
      <svg class="svgBtn" viewBox="0 0 128 51" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g class="svgBtn__group">
              <polygon class="svgBtn__group-1" fill="#000000" points="0 9 120 9 114.688727 40.0279188 13.1874075 49"></polygon>
              <path class="svgBtn__group-2" d="M3.61737022,-1 L126.185722,-1 L120.544374,31.9561841 L17.4858776,41.06591 L3.61737022,-1 Z" id="Path-2" stroke="#FFFFFF" stroke-width="2" fill="#14A965"></path>
          </g>
      </svg>
      <span class="svgBtn__text">Read More</span>
  </a>
      </div>
      </div>
      </div>`;
    });

    return postsHTML;
  }
